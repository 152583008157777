<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="900px" eager>
      <v-card>
        <v-card-title>
          <h4>
            {{ Tdr_Ob.fileName }}
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <VueDocPreview
          v-if="
            Tdr_Ob.fileType == 'doc' ||
              Tdr_Ob.fileType == 'docx' ||
              Tdr_Ob.fileType == 'xls' ||
              Tdr_Ob.fileType == 'xlsx' ||
              Tdr_Ob.fileType == 'XLS' ||
              Tdr_Ob.fileType == 'XLSX'
          "
          :value="Tdr_Ob.fileLink"
          type="office"
        />
        <vue-friendly-iframe
          v-if="Tdr_Ob.fileType == 'pdf'"
          :src="Tdr_Ob.fileLink"
          class="w-62em"
          frameborder="0"
          loading="lazy"
        ></vue-friendly-iframe>
      </v-card>
    </v-dialog>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Mot de bienvenue 
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Présentation (Acheteur /Autorité Contractante)
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">
          Domaine d’intervention (Acheteur /Autorité Contractante) 
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 4" step="4">
          Objectif de la mission (Acheteur / Autorité Contractante)
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 5" step="5">
          Partenaires au développement
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <tiptap-vuetify class="" v-model="info_home.welcomeWord" :extensions="extensions" />
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-btn color="primary" @click="e1 = 2">
                Valider et continuer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <tiptap-vuetify class="" v-model="info_home.presentation" :extensions="extensions" />
          <div class="mt-5">
            <v-btn color="mt-2 primary" @click="e1 = 3">
              Valider et continuer
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <tiptap-vuetify class="" v-model="info_home.projectArea" :extensions="extensions" />
          <div class="mt-5">
            <v-btn color="primary" @click="e1 = 4">
              Valider et continuer
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <tiptap-vuetify class="" v-model="info_home.purpose" :extensions="extensions" />
          <div class="mt-5">
            <v-btn color="primary" @click="e1 = 5">
              Valider et continuer
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="5">
          <tiptap-vuetify class="" v-model="info_home.partner" :extensions="extensions" />
          <div class="mt-5">
            <v-btn color="primary" @click="sendHomeInfo">
              Soumettre
            </v-btn>

            <v-btn text>
              Annuler
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row class="mt-5">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Modification/Ajout d’un personnel à l’organigramme de l’Acheteur/Autorité Contractante</v-card-title>
          <v-card-text>
            <v-text-field filled label="Saisir le Nom et prénoms du personnel" v-model="member.name" persistent-hint></v-text-field>
            <v-text-field
              filled
              label="Saisir le Titre / Fonction du personnel"
              v-model="member.title"
              persistent-hint
            ></v-text-field>
            <v-autocomplete
              v-if="getlistChartMemebers"
              v-model="member.value"
              :items="getlistChartMemebers"
              :item-text="item => item.name + ' - ' + item.title"
              item-value="id"
              outlined
              label="Choisir la hiérarchie du service"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="resetChart" color="red" right>
              <v-icon class="mr-2" color="white">mdi-restart</v-icon>Supprimer l’organigramme
            </v-btn>
            <v-btn @click="addChartMember" color="primary" right>
              <v-icon class="mr-2">mdi-account-multiple</v-icon>AJOUTER LE PERSONNEL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="infoHome">
        <v-card>
          <v-card-title>Aperçu de la présentation de l’organigramme (Acheteur/Autorité Contractante)</v-card-title>
          <v-card-text v-if="infoHome.data">
            <organization-chart :datasource="infoHome.data.organigramme[0]"></organization-chart>
          </v-card-text>
          <v-card-text v-if="!infoHome.data">
            <organization-chart :datasource="ds"></organization-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Ajout des documentations
            <v-spacer></v-spacer>

            <v-file-input
              v-model="home_docs"
              class="mr-5"
              label="Insérer la documentation"
              truncate-length="25"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-btn color="primary" @click="addHomeDocs">
              Publier la documentation
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <div v-if="!infoHome.data">
                PAS DE DOCUMENTS
              </div>
              <v-row v-if="infoHome.data">
                <v-col v-for="doc in infoHome.data.docs" :key="doc.id" cols="6" md="2">
                  <v-card>
                    <v-card-title>
                      <h6 class="text-truncate">{{ doc.fileName }}</h6>
                    </v-card-title>
                    <v-card-text v-if="doc.fileType == 'pdf'">
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/pdf.png"
                          lazy-src="@/assets/pdf.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                    <v-card-text v-if="doc.fileType == 'docx' || doc.fileType == 'doc'">
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/word.png"
                          lazy-src="@/assets/word.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                    <v-card-text
                      v-if="
                        doc.fileType == 'xls' ||
                          doc.fileType == 'xlsx' ||
                          doc.fileType == 'XLS' ||
                          doc.fileType == 'XLSX'
                      "
                    >
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/excel.png"
                          lazy-src="@/assets/excel.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";
import OrganizationChart from "vue-organization-chart";
import { mapGetters } from "vuex";
import "vue-organization-chart/dist/orgchart.css";
import VueDocPreview from "vue-doc-preview";

export default {
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify, VueDocPreview, OrganizationChart },
  data: () => ({
    Tdr_Ob: "",
    dialog: false,
    member: {
      value: null,
      name: "",
      title: ""
    },
    is_boss: true,
    ds: {
      id: "1",
      name: "Empty",
      title: "General manager",

      children: [
        {
          id: "2",
          name: "Empty",
          title: "Head of HR"
        },
        {
          id: "3",
          name: "Empty",
          title: "Head of Technique"
        }
      ]
    },
    info_home: {
      welcomeWord: `
      <h3>Pour votre information,  l’ancien contenu sera écrasé par le nouveau éventuellement.</h3>
      <p>Veuillez saisir le nouveau ‘’mot de bienvenue’’.</p>
    `,
      presentation: `
      <h3>Pour votre information, l’ancien contenu sera remplacé par le nouveau éventuellement.</h3>
      <p>Veuillez saisir la nouvelle présentation (Acheteur /Autorité Contractante).</p>
    `,
      projectArea: `
      <h3>Pour votre information, l’ancien contenu sera remplacé par le nouveau éventuellement.</h3>
      <p>Veuillez saisir le Domaine d’intervention (Acheteur /Autorité Contractante).</p>
    `,
      purpose: `
      <h3>Pour votre information, l’ancien contenu sera remplacé par le nouveau éventuellement.</h3>
      <p>Veuillez saisir les Missions / Composantes.</p>
    `,
      partner: `
      <h3>Pour votre information, l’ancien contenu sera remplacé par le nouveau éventuellement.</h3>
      <p>Veuillez saisir les informations relatives aux Partenaires au développement.</p>
    `
    },
    home_docs: [],
    e1: 1,
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,

      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    treeData: [
      { text: "Item 1" },
      { text: "Item 2" },
      { text: "Item 3", state: { selected: true } },
      { text: "Item 4" }
    ],
    bossInfo: [],
    employee: {
      name: "",
      staff: ""
    },
    dialog: false,
    active: [],
    open: [],
    selectionType: "leaf",
    selection: [],
    item: [
      {
        parent: null,
        id: 1,
        fullname: "Abdourahmane Dieylani Fall",
        profession: "Directeur Generale",
        children: [
          {
            id: 2,
            name: "Moussa Abdel Cann",
            staff: "Directeur Finance",
            children: []
          },

          {
            id: 3,
            name: "Kankan Moussa",
            staff: "Chef Technique",
            children: []
          }
        ]
      }
    ],
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Configurations",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/getHomeChart");
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/GetAllMembers");
    //  let ds = [];
    //  let source = this.$store.dispatch('Auth/getHomeChart');
  },
  computed: {
    ...mapGetters({
      infoHome: "Auth/get_all_cnfig_home",
      getHomeDocs: "Auth/getHomeDocs",
      getlistChartMemebers: "Auth/getlistChartMemebers"
    })
  },
  methods: {
    resetChart() {
      this.$store.dispatch("Auth/resetChart");
    },
    assignDoc(doc) {
      this.Tdr_Ob = doc;
      this.dialog = !this.dialog;
    },
    async downloadWithVueResource(doc) {
      await this.assignDoc(doc);
    },
    addChartMember() {
      let id = 1;
      if (this.member.value) {
        id = this.member.value;
      }
      this.$store.dispatch("Auth/addMemeberChart", [id, this.member]);

      (this.member.value = null), (this.member.name = ""), (this.member.title = "");
    },
    sendHomeInfo() {
      const data = {
        welcomeWord: this.info_home.welcomeWord,
        presentation: this.info_home.presentation,
        projectArea: this.info_home.projectArea,
        purpose: this.info_home.purpose,
        partner: this.info_home.partner
      };

      this.$store.dispatch("Auth/postHomeInfo", data);
      // console.log(this.info_home);
    },
    addHomeDocs() {
      const data = new FormData();
      data.append("file", this.home_docs);
      this.$store.dispatch("Auth/addHomeDocs", data);

      this.home_docs = [];
    }
  }
};
</script>
<style>
.orgchart-container {
  border: none;
}
.orgchart .node .title {
  background-color: rgb(3, 89, 173);
}
.orgchart .lines .downLine {
  background-color: rgb(63, 211, 199);
}
.orgchart .lines .topLine {
  border-top: 2px solid rgb(63, 211, 199);
}
.orgchart .lines .rightLine {
  border-right: 1px solid rgb(63, 211, 199);
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
